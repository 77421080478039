<template>
  <div class="successPanel">
    <div class="content">
      <img src="../assets/img/logo.png" alt="VTG LOGO" />
      <h1 class="mt-3">Thank you!</h1>
      <h2 class="mt-2">Document Uploaded successfully</h2>
      <router-link to="/dashboard"
        ><h3 class="mt-4">Go To Dashboard</h3></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  created(){
     if (this.$store.getters.getAuth == false) {
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.successPanel {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .content {
      width:100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    > img {
      width: 300px;
    }

    > h1 {
      font-weight: 800 !important;
      color: #333333;
    }
    > h2 {
      font-weight: 600 !important;
    }

    a {
      > h3 {
        color: #005eaa;
        font-weight: 500 !important;
      }
    }
  }
}
</style>